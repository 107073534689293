import { useState } from 'react';
import { IconChevronLeft16, IconChevronRight16 } from '@fabric-ds/icons/react';

import {
    Calendar,
    type CalendarProps,
    type CalendarEvent,
    CountryCodes,
    type DateItem,
    Languages,
    monthNames,
} from '@/components/calendar/Calendar';

import { useCalendarDates } from '@/hooks/useCalendarDates';
import { useCalendarHandleDateSelect } from '@/hooks/useCalendarHandleDateSelect';
import { EventTypes, type AvailabilityRules } from '@/domain';

import { useCalendarEvents } from './hooks/useCalendarEvents';

export type BasicCalendarProps = Partial<CalendarProps> & {
    availabilityInfo?: AvailabilityRules;
    calendarDayPropMiddleware?: (dateItem: DateItem) => Record<string, string | boolean>;
    className?: string;
    countryCode?: CountryCodes;
    dayDataByMonth?: Record<string, Record<string, string>>;
    events: CalendarEvent[];
    language?: Languages;
    offsetStartDay?: number;
    onDaySelect?: (dateItem: DateItem) => void;
    onMonthChange?: (date: Date) => void;
    onReset: () => void;
    onSelectChange?: (dates: { startDate: Date | null; endDate: Date | null }) => void;
    selectedDates: {
        to: Date | null;
        from: Date | null;
    };
    title?: string;
    today?: Date;
};

export const BasicCalendar = ({
    availabilityInfo,
    calendarDayPropMiddleware,
    className,
    countryCode = CountryCodes.NO,
    dayDataByMonth = {},
    events = [],
    language = Languages.NO,
    offsetStartDay = 1,
    onDaySelect = () => undefined,
    onMonthChange = () => undefined,
    onReset,
    onSelectChange = (_) => undefined,
    selectedDates,
    title,
    today = new Date(),
}: BasicCalendarProps) => {
    const validMonthRange: [number, number] =
        typeof availabilityInfo?.advanceBookingLimit === 'number' ? [0, availabilityInfo?.advanceBookingLimit] : [0, 24];
    const selectedStartDate = selectedDates?.from;
    const selectedEndDate = selectedDates?.to;
    const todayMonthDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const defaultSelectedMonthDate = selectedStartDate
        ? new Date(selectedStartDate.getFullYear(), selectedStartDate.getMonth(), 1)
        : todayMonthDate;
    const [activeMonthDate, setActiveMonthDate] = useState<Date>(defaultSelectedMonthDate);
    const { updatedEvents } = useCalendarEvents({
        activeMonthDate,
        availabilityInfo,
        events,
        selectedEndDate,
        selectedStartDate,
        today,
    });
    const {
        disallowedFollowingMonthDate,
        disallowedPreviousMonthDate,
        monthIndex,
        selectedNextMonthDate,
        selectedPreviousMonthDate,
        year,
    } = useCalendarDates({
        events,
        selectedDates,
        activeMonthDate,
        today,
        validMonthRange,
    });
    const { handleDaySelect } = useCalendarHandleDateSelect({
        availabilityInfo,
        events,
        onSelectChange,
        onDaySelect,
        selectedEndDate,
        selectedStartDate,
        today,
    });
    const isResetButtonDisabled = !(selectedStartDate || todayMonthDate.getTime() < activeMonthDate.getTime());

    const shiftDisplayMonth = (monthDifference: number) => {
        const newDate = new Date(activeMonthDate.getFullYear(), activeMonthDate.getMonth() + monthDifference, 1);
        setActiveMonthDate(newDate);
        typeof onMonthChange === 'function' && onMonthChange(newDate);
    };

    const handleReset = () => {
        setActiveMonthDate(todayMonthDate);
        onReset();
    };

    // Set disabled on day button elements if in past or is a booking
    const createCalendarDayProps = (dateItem: DateItem): Record<string, string | boolean> => {
        const isDaySelected = Boolean(
            dateItem.activeEvents?.some((event) => (event.status === 'start' || event.status === 'end') && event.type === 'select'),
        );
        const { isExpired } = dateItem.states ?? {};
        const isMinimumRentalDuration = Boolean(dateItem.activeEvents?.some((item) => item.type === EventTypes.MINIMUM_RENTAL_DURATION));
        const isInvalidCheckinDay = Boolean(dateItem.activeEvents?.some((item) => item.type === EventTypes.INVALID_CHECKIN_DAY));
        const isPreviousDayDisabled = Boolean(selectedStartDate && dateItem.date.getTime() < selectedStartDate.getTime());
        const extend = calendarDayPropMiddleware?.(dateItem);

        return {
            'aria-selected': isDaySelected,
            disabled: isExpired || isMinimumRentalDuration || isInvalidCheckinDay || isPreviousDayDisabled,
            'data-requirement-minimum-rental-duration': String(availabilityInfo?.minRentalDuration ?? ''),
            ...extend,
        };
    };

    return (
        <div className={className} data-testid="BasicCalendar">
            <div className="Calendar-headerTitle">
                {title && (
                    <span className="Calendar-headerTitleText" id="objectCalendarHeading">
                        {title}
                    </span>
                )}
                <button
                    type="button"
                    className="Calendar-resetButton"
                    onClick={handleReset}
                    data-testid="object-calendar-reset"
                    disabled={isResetButtonDisabled}>
                    Nullstill
                </button>
            </div>

            <div className="Calendar-nav">
                <button
                    type="button"
                    className="Calendar-navButton Calendar-navButton--left"
                    onClick={() => shiftDisplayMonth(-1)}
                    disabled={disallowedPreviousMonthDate.getTime() > selectedPreviousMonthDate.getTime()}
                    aria-label="Gå til forrige måned"
                    data-testid="BasicCalendar-prevMonthButton">
                    <IconChevronLeft16 />
                </button>
                <p className="m-0">
                    {monthNames[language][monthIndex]} {year}
                </p>
                <button
                    type="button"
                    className="Calendar-navButton Calendar-navButton--right"
                    onClick={() => shiftDisplayMonth(1)}
                    disabled={disallowedFollowingMonthDate.getTime() < selectedNextMonthDate.getTime()}
                    aria-label="Gå til neste måned"
                    data-testid="BasicCalendar-nextMonthButton">
                    <IconChevronRight16 />
                </button>
            </div>

            <Calendar
                createDayProps={createCalendarDayProps}
                countryCode={countryCode}
                dayDataByMonth={dayDataByMonth}
                events={updatedEvents}
                language={language}
                offsetStartDay={offsetStartDay}
                onDaySelect={handleDaySelect}
                activeMonthDate={activeMonthDate}
                today={today}
            />
        </div>
    );
};

export { CountryCodes, Languages } from '@/components/calendar/Calendar';
