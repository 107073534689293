import getConfig from 'next/config';

import { trackCheckPriceAndAvailability } from '@/lib/tracking/tracking';
import { formatISODate, safeDifferenceInCalendarDays } from 'shared/util/datetime-fns';

const {
    publicRuntimeConfig: { contextPath },
} = getConfig();

interface PeriodParamProps {
    startDate: Date | null;
    endDate: Date | null;
    ownerName?: string;
}
const generatePeriodParamsForUrl = ({ startDate, endDate, ownerName }: PeriodParamProps): string => {
    if (startDate && endDate && ownerName === 'DanCenter') {
        return `&firstdate=${formatISODate(startDate)?.replaceAll('-', '')}&days=${safeDifferenceInCalendarDays(startDate, endDate) ?? 1}`;
    }

    return '';
};

export const renderImportAdButton = ({ owner, objectData, startDate, endDate }) => {
    const deepLinkParams = generatePeriodParamsForUrl({ startDate, endDate, ownerName: owner?.name });
    const linkWithParams = `${contextPath}/ut?finnkode=${objectData.adId}&deepLinkParams=${
        deepLinkParams !== '' ? btoa(deepLinkParams) : ''
    }`;

    return owner ? (
        <>
            <a
                className="button button--primary w-full max-w-full"
                onClick={() => trackCheckPriceAndAvailability({ objectData })}
                href={linkWithParams}
                target="_blank"
                rel="noopener noreferrer nofollow external"
                data-testid="importAdButton">
                Se pris og tilgjengelighet
            </a>
            {!objectData.isImport && <p className="text-12">OBS! I enkelte perioder kan man kun leie hele uker.</p>}
        </>
    ) : null;
};
