import type { HostBadgesData } from '@/hooks/useHostBadges';
import styles from './badges.module.css';

const BADGE_IMAGES = {
    highResponseRate: (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                d="M7.99976 15.5C12.1419 15.5 15.4998 12.1421 15.4998 8C15.4998 3.85786 12.1419 0.5 7.99976 0.5C3.85762 0.5 0.499756 3.85786 0.499756 8C0.499756 12.1421 3.85762 15.5 7.99976 15.5Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.99842 12.5028C8.83712 12.5028 9.65915 12.2685 10.3718 11.8262C11.0797 11.387 11.6517 10.7599 12.0243 10.015L3.96509 10C4.33747 10.7515 4.9124 11.384 5.62504 11.8262C6.33769 12.2685 7.15972 12.5028 7.99842 12.5028Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.4998 7.00032C12.3964 6.70779 12.2049 6.4545 11.9516 6.27534C11.6983 6.09619 11.3957 5.99998 11.0854 5.99998C10.7752 5.99998 10.4726 6.09619 10.2193 6.27534C9.97124 6.45076 9.78246 6.69724 9.67768 6.98205"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.49978 7.00032C3.60313 6.70779 3.79464 6.4545 4.04794 6.27534C4.30124 6.09619 4.60386 5.99998 4.91412 5.99998C5.22437 5.99998 5.52699 6.09619 5.78029 6.27534C6.0292 6.45139 6.21845 6.69903 6.323 6.98515"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
};

const BADGE_LABELS = {
    highResponseRate: 'Svarer raskt',
};

type Props = {
    data: HostBadgesData;
};

export const Badges = (props: Props) => {
    const badges: string[] = Object.entries(props.data).reduce((acc, [k, v]) => {
        if (v === true && BADGE_LABELS[k]) {
            acc.push(k);
        }

        return acc;
    }, [] as string[]);

    return (
        <ul className={styles.list}>
            {badges.map((badge_id) => (
                <li className={styles.item} key={badge_id}>
                    {BADGE_IMAGES[badge_id]}
                    {BADGE_LABELS[badge_id]}
                </li>
            ))}
        </ul>
    );
};
