export const ProfileFallbackAvatar = ({ className }: { className?: string }) => (
    <svg viewBox="0 0 220 220" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className={className}>
        <g fill="none" fillRule="evenodd">
            <circle fill="#B5EEFE" cx="110" cy="110" r="110" />
            <g fill="#FFFFFF">
                <path
                    d="M31 174.077C49.622 197.21 78.093 212 110 212c31.907 0 60.378-14.79
                        79-37.923C172.56 147.61 143.325 130 110 130s-62.56 17.61-79 44.077z"
                />
                <circle cx="110" cy="76" r="46" />
            </g>
        </g>
    </svg>
);
