import { useState } from 'react';

import { thousandSeparated } from 'shared/util/formatting';

interface PricePartProps {
    title: string;
    value: number;
    isNegateValue?: boolean;
    norwegianAddress?: boolean;
    currency?: string;
    extraPriceInformation?: {
        description: string;
        link?: {
            text: string;
            url: string;
        };
    };
}

export const PriceDetail = ({ title, value, isNegateValue, extraPriceInformation, norwegianAddress = true, currency }: PricePartProps) => {
    const [showExtraPriceInformation, setShowExtraPriceInformation] = useState(false);

    const handleTitleClick = () => {
        setShowExtraPriceInformation(!showExtraPriceInformation);
    };

    const renderValue = () => {
        if (norwegianAddress) {
            return `${thousandSeparated(value)} kr`;
        }
        return currency ? `${currency} ${thousandSeparated(value)}` : `${thousandSeparated(value)}`;
    };

    return value > 0 ? (
        <div>
            {title === 'Totalpris' && <hr className="divider" />}
            <p className={`grid grid-cols-3 gap-16 my-8 ${title === 'Totalpris' && 'font-bold'}`}>
                {extraPriceInformation ? (
                    <button className="col-span-2 m-0 underline cursor-pointer text-left" type="button" onClick={handleTitleClick}>
                        {title}
                    </button>
                ) : (
                    <span className="col-span-2 m-0 text-left">{title}</span>
                )}
                <data value={value} className="text-right">
                    {isNegateValue ? '- ' : ''}
                    {renderValue()}
                </data>
            </p>
            {showExtraPriceInformation && (
                <p className="text-left text-12 text-gray-500 pb-10">
                    {extraPriceInformation?.description}
                    {extraPriceInformation?.link && (
                        <a href={extraPriceInformation.link.url} target="_blank" rel="noreferrer">
                            {extraPriceInformation.link.text}
                        </a>
                    )}
                </p>
            )}
        </div>
    ) : null;
};
