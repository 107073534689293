import { IconLinkExternal16 } from '@fabric-ds/icons/react';

import { trackClick } from '@/lib/tracking/tracking';
import { Panel } from '../panel/Panel';

import styles from './Links.module.css';

type LinkProps = {
    url: string;
    text?: string;
};

export const Links = ({ links }: { links: LinkProps[] }) => {
    const pulseData = (url, index) => ({
        type: 'Click',
        intent: 'Open',
        name: 'Useful links',
        object: {
            id: `useful_link_${index}`,
            type: 'UIElement',
            elementType: 'Link',
            hasExtendedProfile: false,
        },
        target: {
            id: url,
            type: 'ExternalContent',
            name: 'Go to webpage',
            referralCommission: false,
            provider: 'TODO', // TODO what to put here? Name of ad owner?
        },
        vertical: {
            name: 'travel',
            subVertical: 'holiday_rental',
        },
    });

    return links && links.length > 0 ? (
        <Panel title="Nyttige lenker" info bleed>
            <div data-testid="adMoreInfo">
                {links.map((info, index) => (
                    <div key={`span-${info.url}`} className="flex items-center">
                        <IconLinkExternal16 />
                        <a
                            key={`${info.url}-${info.text}`}
                            rel="noopener noreferrer nofollow external"
                            target="_blank"
                            href={info.url}
                            className={`${styles.Link} link ml-8`}
                            // Pulse tracker id: FHH_ev03
                            onClick={() => trackClick(pulseData(info.url, index))}
                            data-testid="objectLink">
                            {info.text ? info.text : info.url}
                        </a>
                    </div>
                ))}
            </div>
        </Panel>
    ) : null;
};
