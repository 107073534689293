import getConfig from 'next/config';

import { ProfileButton } from '../profileButton/ProfileButton';
import { ProfileCompany } from '../profileCompany/ProfileCompany';
import { ProfilePublic } from '../profilePublic/ProfilePublic';
import type { ObjectData, ProfileIdentity } from '@/domain';
import type { UnleashToggles } from '@/domain';
import type { HostBadgesData } from '@/hooks/useHostBadges';

const {
    publicRuntimeConfig: { contextPath },
} = getConfig();

export type ProfileProps = {
    objectData: ObjectData;
    isPreview: boolean;
    hostBadges: HostBadgesData;
    profileIdentity?: ProfileIdentity;
    unleash: UnleashToggles;
    onHomepageClick: () => void;
    onMoreAdsClick: () => void;
};

export const Profile = ({ objectData, isPreview, hostBadges, profileIdentity, unleash, onHomepageClick, onMoreAdsClick }: ProfileProps) => {
    const { owner, adId, isAdmin, isImport, moreAdsQuery, organisation } = objectData;
    const badges = unleash?.showHostBadge ? hostBadges : {};

    if (!owner) {
        return null;
    }

    // If import/admin ads don't have any data they can use, don't show the profile at all
    if ((isAdmin || isImport) && !owner.mobile && !owner.phone && !organisation) {
        return null;
    }

    return (
        <div className="p-16 border rounded-8" data-testid="objectPage-profile">
            <div data-testid="adProfile" className="text-center">
                {(isAdmin || isImport) && (
                    <ProfileCompany
                        ownerName={owner.name}
                        ownerPhone={owner.phone || undefined}
                        ownerMobile={owner.mobile || undefined}
                        organisation={organisation}
                    />
                )}

                {objectData.isPrivate && <ProfilePublic identity={profileIdentity} badges={badges} />}
                <ProfileButton objectData={objectData} isPreview={isPreview} />
                {!isImport && owner.url && (
                    <a
                        href={`${contextPath}/ut?finnkode=${adId}&deepLinkParams=`}
                        data-testid="webpage"
                        className="block"
                        target="_blank"
                        rel="noopener noreferrer nofollow external"
                        onClick={() => onHomepageClick()}>
                        Hjemmeside
                    </a>
                )}
                {moreAdsQuery && !unleash?.showHostBadge && (
                    <a
                        data-testid="more-ads"
                        className="block"
                        href={`${contextPath}/resultat/?${moreAdsQuery}`}
                        onClick={() => onMoreAdsClick()}>
                        Andre annonser fra samme utleier
                    </a>
                )}
            </div>
        </div>
    );
};
